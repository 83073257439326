import { isMobileOnly } from "react-device-detect";
import { motion } from "framer-motion";
import React from "react";
import { string } from "prop-types";
import vector12 from "../../images/svg/vector-12.svg";
import heroImg from "../../images/hero-images/restaurant-hub.png";
import Image from "../../components/ImageQuerys/HeroImages";
import useStartMotion from "../../hooks/use-start-motion";

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const Hero = ({ imageName, mobileImageName, sideText, componentSizing }) => {
  const { ref, controls } = useStartMotion();

  return (
    <motion.section
      className={`w-full mx-auto relative ${componentSizing} basic-hero-image`}
      ref={ref}
      initial={isMobileOnly ? "visible" : "hidden"}
      variants={fadeVariants}
      animate={controls}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <img src={vector12} alt="vector" className="absolute left-0 -bottom-40" />

      <div className="relative max-w-1440 w-full px-4 lg:px-6 xl:px-10 mx-auto">
        <Image
          className="rounded-lg md:hidden"
          imageName={mobileImageName}
          style={{ borderRadius: "12px !important" }}
        />
      
        <Image className="hidden md:block rounded-lg" imageName={imageName} />
      
        {/* {isMobileOnly ? (
          <Image
            className="rounded-lg"
            imageName={mobileImageName}
            style={{ borderRadius: "12px !important" }}
          />
        ) : (
          <Image className="rounded-lg" imageName={imageName} />
        )} */}
        {/* <Image imageName="restaurant-hub.png" /> */}
        {/* <img className="w-full h-auto" src={heroImg} alt="hero" /> */}
        <div
          className={`
          absolute transform -rotate-90
          -left-8 md:left-8 lg:left-16 bottom-24
          text-white text-[12px]
        `}
        >
          {sideText}
        </div>
      </div>

      <style jsx="true" global>{`
        .basic-hero-image .gatsby-image-wrapper img {
          border-radius: 12px !important;
        }
      `}</style>
    </motion.section>
  );
};

Hero.propTypes = {
  imageName: string,
  mobileImageName: string,
  sideText: string,
  componentSizing: string,
};

Hero.defaultProps = {
  imageName: "restaurant-hub.png",
  mobileImageName: "restaurant-hub-mobile.png",
  sideText: "MAXIMISE PROFIT MARGINS",
  componentSizing: "-mt-24 md:-mt-44 lg:-mt-80",
};

export default Hero;
